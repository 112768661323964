import * as React from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';
import { useFormTransition } from '../../../utils/hooks/useFormTransition';

import { Section } from '../../layout/Section';
import { Container } from '../../layout/Container';
import { BackgroundLoad } from '../../layout/BackgroundLoad';
import { Thanks } from '../../layout/Thanks';

import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';

import { AccordionGeneral } from '../../partials/AccordionGeneral';
import { AccordionBenefits } from '../../partials/AccordionBenefits';
import { useFadeTransition } from '../../../utils/hooks/useFadeTransition';
import { Seo } from '../../../utils/seo/Index';

import { AboutValuationText } from '../../partials/AboutValuationText';
import { useValuationContext } from '../../../utils/hooks/useValuationContext';
import { ValuationForm } from '../../partials/forms/Valuation';
import { PageType } from '../../../__types__/generated/globalTypes';
import { ValuationContextProvider } from '../../../utils/context/ValuationContext';

const Index: React.FC = () => {
  const { init, posted } = useValuationContext();

  React.useEffect(() => {
    init({
      pageType: PageType.GENERELL
    });
  }, []);

  const { fadeIn: pageFadeIn } = useFadeTransition({
    immidiate: true
  });

  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });

  return (
    <>
      <Seo
        title="Verdivurdering - PrivatMegleren"
        description="Som kunde hos PrivatMegleren får du en skreddersydd prosess tilpasset dine behov og ønsker."
      />
      <Container className="padding-top" style={pageFadeIn}>
        <FormWrapper style={fadeOut}>
          <Section className="form-section" style={{ paddingBottom: '10px' }}>
            <Heading tag="h1" center={true}>
              Slik foregår en verdivurdering
            </Heading>
            <Paragraph center={true} className="form-text">
              Per telefon avtaler vi et tidspunkt som passer for en befaring av
              din eiendom. Når vi møtes går vi først gjennom eiendommen. Da
              forteller du oss om eiendommen og eventuell oppgraderinger som er
              gjort. Har du spørsmål knyttet til hva som er lurt å gjøre før et
              salg, svarer vi gjerne på det.
            </Paragraph>
            <Paragraph center={true} className="form-text">
              I forkant av befaring av eiendommen har vår megler innhentet
              tilgjengelig informasjon, som eiendomspriser i området. Når vi er
              ferdig med å se på eiendommen, setter vi oss ned sammen og
              gjennomgår din eiendom i forhold til dagens eiendomsmarked. Basert
              på gjennomgang av eiendommen og markedet vil megler nå kunne
              fortelle deg hva din eiendom er verdt i dagens marked.
            </Paragraph>
            <Paragraph center={true} className="form-text">
              Har du andre spørsmål, svarer vi mer enn gjerne på det. Vi kan
              gjerne si litt om dagens marked, dersom du skal kjøpe eiendom før
              du skal selge. Ønsker du mer hjelp i forbindelse med ditt
              eiendomskjøp, tilbyr vi også
              <a
                href="https://privatmegleren.no/kjop"
                style={{ display: 'inline', margin: '0 0 0 5px' }}
                target="_blank"
              >
                kjøpsmegling
              </a>
              .
            </Paragraph>
            <Paragraph center={true} className="form-text">
              Vi ser frem til å høre fra deg.
            </Paragraph>
          </Section>
          <ValuationForm />
          <Section className="form-section">
            <AboutValuationText />
          </Section>
          <Section className="form-section">
            <AccordionBenefits />
          </Section>
        </FormWrapper>
        <Thanks style={fadeIn}>
          <Section className="form-section">
            <Heading tag="h1" center={true}>
              Tusen takk
            </Heading>
            <Paragraph center={true}>
              En eiendomsmegler vil ta kontakt med deg så snart som mulig.
            </Paragraph>
            <Paragraph center={true}>
              I mellomtiden kan du lese mer om salg av eiendom her.
            </Paragraph>
            <AccordionGeneral />
          </Section>
          <Section className="form-section" style={{ paddingTop: 0 }}>
            <AccordionBenefits />
          </Section>
        </Thanks>
      </Container>
      <BackgroundLoad
        opacity={0.3}
        url="https://cdn.reeltime.no/pm_assets/img/cbimage.jpg"
      />
    </>
  );
};

const SlikForegaarEnVerdivurdering: React.FC = (props) => {
  return (
    <ValuationContextProvider>
      <Index {...props} />
    </ValuationContextProvider>
  );
};

export default SlikForegaarEnVerdivurdering;

const FormWrapper = styled(animated.div)``;
